import axios from "axios"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useProfiles } from "../lib/profiles.js";
import { TextInput } from "../Components/Input.js";
import { ButtonOk, CopyButton } from "../Components/Buttons.js";
import { Spinner, withSpinner  } from "../Components/Spinner.js";
import { Page, PageTitle } from "../Components/page.js";


function TwinInfo({twin}) {
  return <>
    <div className="flex flex-col gap-1 select-none">
      <div className="flex flex-row justify-between">
        <p className="text-sm font-medium text-zinc-400">Account URL</p>
        <CopyButton value={twin.hostname} title="Copy hostname" className=" size-5"/>
      </div>
      <p className="text-wrap break-all border rounded p-1">{twin.hostname}</p>
    </div>
    <div className="flex flex-col gap-1 select-none">
      <div className="flex flex-row justify-between">
        <p className="text-sm font-medium text-zinc-400">Account Access Code</p>
        <CopyButton value={twin.apiKey} title="Copy API key" className="align-baseline size-5"/>
      </div>
      <p className="text-wrap break-all border rounded p-1">{twin.apiKey}</p>
    </div>
  </>
}

export default function Signup() {
  let navigate = useNavigate()
  let { setProfile, setActiveProfile } = useProfiles();
  let [ newTwin, setNewTwin ] = useState(null)
  let [ isCreating, setIsCreating ] = useState(null);
  let { mutate: createTwin } = useMutation({
    onMutate: _ => setIsCreating(true),
    onSettled: _ => setIsCreating(false),
    mutationFn: async function createTwinMutation({name, email}) {
      return withSpinner(setIsSpinning, async _ => {
        let axiosResp = await axios.request({
          method: "POST",
          url: "https://signup.tracker.todaq.net",
          data: { name, email },
          responseType: "json"
        })
        return axiosResp.data.body
      });
    },
    onSuccess: function createTwinSuccess({ hostname, apiKeys, error }) {
      if (error) console.warn(error); // TODO(sfertman): expose this in UI somehow!
      setNewTwin({ hostname: `https://${hostname}`, apiKey: apiKeys[0] });
    },
    onError: function createTwinError(...args) {
      console.error(...args)
    }
  })
  // Example twins we can work with:

  // https://41f33a6c397b4bca97bb5616acd7fe39.tracker-demo.biz.todaq.net
  // c99c7e58-a71e-4529-8b88-3cf5dc92a7a3

  // https://4103432af1f870c5289fb951ad236d7d.tracker-demo.biz.todaq.net
  // 69de0132-60ea-4b30-8faa-efbdbfa22ec6


  // Staging twins
  // https://411026958e9e2a4c7852367bbe018075.tq.biz-staging.todaq.net
  // 92a511a7-988a-4880-becb-101af1291b98

  // https://4125b9aaebfb403b51827de29887103b.tq.biz-staging.todaq.net
  // 9f671957-5c3d-4393-9fd4-122e8aec5a92


  function onSubmit(e) {
    e.preventDefault();
    let formData = new FormData(e.target);
    let name = formData.get("name");
    let email = formData.get("email");
    withSpinner(setIsSpinning, _ => {
      createTwin({ name, email });
    })
  }

  let [ isSpinning, setIsSpinning ] = useState(null);

  return <Page>
    {newTwin ?
      <div className="flex flex-col gap-6 px-6 size-fit">
        <PageTitle>New Twin Created</PageTitle>
        <TwinInfo twin={newTwin} />
        <ButtonOk onClick={()=>{
          let {hostname: url, apiKey} = newTwin;
          setProfile(url, {client: {url, apiKey}});
          setActiveProfile(url)
          return navigate("/home")
          }}>
            Login
        </ButtonOk>
      </div>
      :
      <form onSubmit={onSubmit} className="flex flex-col gap-6">
        <PageTitle>Create New Twin</PageTitle>
        <TextInput
          label="Name"
          id="input_name"
          name="name"
          required
          disabled={isCreating}
          placeholder="Enter name"/>
        <TextInput
          label="Email"
          id="input_email"
          name="email"
          required
          disabled={isCreating}
          pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
          placeholder="Enter email"/>
        <ButtonOk type="submit" disabled={isCreating}>Request account</ButtonOk>
        <div className="flex flex-row gap-2" >
          <Spinner className="!size-5" hidden={!isSpinning} />
          <div hidden={!isSpinning} className="text-info">Creating </div>
        </div>
      </form>
    }
  </Page>
}
